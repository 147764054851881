<div class="w-full rounded-full bg-[#F0EFEF] overflow-hidden">
  <div
    [ngClass]="singleColor ? 'bg-primary' :{
              'bg-[#F79400]': progress < 70,
              'bg-[#317756]': progress >= 70
            }"
    [style.height.px]="10"
    [style.width.%]="progress"
  ></div>
</div>
