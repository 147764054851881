import {Component, Input} from '@angular/core';
import {Seed} from '../../@models/seed.interface';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../../@commons/ui-components/shared.module';
import {NgClass} from '@angular/common';
import {ProgressBarComponent} from '../../../@commons/@components/progress-bar/progress-bar.component';
import {
  Cultivation
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation.entity';
import {
  SeedListResponse
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@dto/responses/location/seed-list.response';

@Component({
  selector: 'vw-seed-item',
  standalone: true,
  imports: [
    TranslateModule,
    SharedModule,
    NgClass,
    ProgressBarComponent
  ],
  templateUrl: './seed-item.component.html',
  styleUrl: './seed-item.component.scss'
})
export class SeedItemComponent {
  @Input() seed: any;

}
