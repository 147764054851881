import {Component, Input} from '@angular/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'vw-progress-bar',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {
  @Input() progress!: number;
  @Input() singleColor: boolean = false;
  @Input() colorClass: string = 'bg-primary';

}
