import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'base-wizzard-question',
  template: ``,
})

export abstract class WizzardAbstractQuestion  {
  @Input() question: any;
  //@Input() lastAnswer: any;
  @Output() completed: EventEmitter<any> = new EventEmitter<void>();
  @Output() skipped = new EventEmitter<void>();
  @Input() answers: any[] = [];

  sendCompleted(result: any): void {
    setTimeout(() => {
      this.completed.emit(result);
    }, 500);
  }

  public async setAnswers(answers: any[], currentQuestionIndex: number): Promise<void> {
    this.answers = answers;
  }

}
