import {Component, EventEmitter, Output} from '@angular/core';
import {SharedModule} from '../../ui-components/shared.module';

@Component({
  selector: 'vw-close-btn',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './close-btn.component.html',
  styleUrl: './close-btn.component.scss'
})
export class CloseBtnComponent {
  @Output() close = new EventEmitter();
  closeClicked() {
    this.close.emit();
  }
}
