import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Seed } from '../../@models/seed.interface';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../@commons/ui-components/shared.module';
import { NgClass, NgIf } from '@angular/common';
import { ProgressBarComponent } from '../../../@commons/@components/progress-bar/progress-bar.component';
import {
  Cultivation
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation.entity';
import {
  CultivationVariant
} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/generated/@entities/app/cultivation-variant.entity';
import { HexToHsl } from '../../../@commons/pipes/HexToHsl';


@Component({
  selector: 'vw-cultivation-flat-item',
  standalone: true,
  imports: [
    TranslateModule,
    SharedModule,
    NgClass,
    ProgressBarComponent,
    NgIf,
    HexToHsl
  ],
  templateUrl: './cultivation-flat-item.component.html',
  styleUrl: './cultivation-flat-item.component.scss'
})
export class CultivationFlatItemComponent implements OnInit, OnChanges {
  @Input() cultivation: any;
  @Input() selected!: boolean;
  cultivationFileUrl: string = 'https://1c9c8h-hv.myshopify.com/cdn/shop/files/product-img-1.png?v=1738043832';

  setFileUrl() {
    if (this.cultivation && (this.cultivation as any).files?.length > 0) {
      this.cultivationFileUrl = (this.cultivation as any).files[(this.cultivation as any).files.length - 1].cdnUrl;
    }
  }
  ngOnInit() {
    this.setFileUrl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cultivation']) {
      this.setFileUrl();
    }
  }

  getFamily() {
    const hierarchy = this.cultivation.cultivationHierarchy;
    if (hierarchy.type === 'FAMILY') {
      return hierarchy.name;
    } else if (hierarchy.parent.type === 'FAMILY') {
      return hierarchy.parent.name;
    } else {
      return hierarchy.parent.parent.name;
    }
  }


}
