import { Injectable } from '@angular/core';
import { NavigationService } from '../../@commons/services/navigation.service';
import { Observable, of } from 'rxjs';
import { DUMMY_CHECKBOX_OPTIONS, DUMMY_GROWTH_STAGE_OPTIONS, DUMMY_POT_OPTIONS, DUMMY_RADIO_OPTIONS } from '../@models/mockData';
import { CheckboxOptions } from '../@models/checkbox-options.interface';
import { GrowthStageOptions } from '../@models/growth-stage-options.interface';
import { PotOptions } from '../@models/pot-options.interface';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private totalSteps: number = 6; // Total number of onboarding steps
  private currentStep: number = 0; // Tracks the user's current step in the onboarding process
  private percentage: number = 0; // Tracks the completion percentage of the onboarding process

  constructor(private navService: NavigationService) {}

  /**
   * Retrieves the current step index.
   * @returns {number} The current step index.
   */
  getCurrentStep(): number {
    return this.currentStep;
  }

  /**
   * Retrieves the completion percentage.
   * @returns {number} The percentage of completion.
   */
  getPercentage(): number {
    return this.percentage;
  }

  /**
   * Advances to the next onboarding step if conditions are met.
   * @param {number | null} selectedCheckboxIndex - Index of a selected checkbox; required at step 2.
   * @returns {boolean} True if the step was successfully advanced; otherwise, false.
   */
  goToNextStep(selectedCheckboxIndex: number | null): boolean {
if (this.currentStep < this.totalSteps - 1) { // Change to `this.totalSteps - 1` to check for the last step
  // Require checkbox selection at step 2
  if (this.currentStep === 2 && selectedCheckboxIndex === null) {
      return false; // Cannot proceed without selection
  }
  this.currentStep++; // Move to the next step
  this.updatePercentage(); // Update the completion percentage
  return true; // Successfully moved to the next step
}
    return false; // Unable to advance
}


  /**
   * Returns to the previous onboarding step.
   * If at the first step, it navigates back using the navigation service.
   */
  goToPreviousStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--; // Move to the previous step
      this.updatePercentage(); // Update the completion percentage
    } else {
      this.navService.goBack(); // Navigate back if at the first step
    }
  }

  /**
   * Updates the completion percentage based on the current step.
   */
  updatePercentage(): void {
    this.percentage = (this.currentStep / this.totalSteps) * 100; // Calculate percentage completion
  }

  /**
   * Returns the index of the selected option.
   * @param {number} index - The index of the selected option.
   * @returns {number} The index of the selected option.
   */
  selectOption(index: number): number {
    return index; // Return the selected index
  }

  /**
   * Retrieves the primary color used in the onboarding process.
   * @returns {string} The hex color code for the primary color.
   */
  getPrimaryColor(): string {
    return '#317756'; // Primary color hex code
  }

   /**
   * Fetches checkbox options from the dummy data.
   * @returns {Observable<CheckboxOptionsDTO[]>} An observable of the checkbox options.
   */
   getCheckboxOptions(): Observable<CheckboxOptions[]> {
    return of(DUMMY_CHECKBOX_OPTIONS); // Simulate API call with dummy data
  }

/**
   * Fetches pot options from the dummy data.
   * @returns {Observable<Option[]>} An observable of the pot options.
   */
getPotOptions(): Observable<PotOptions[]> {
  return of(DUMMY_POT_OPTIONS); // Simulate API call with dummy data
}

/**
 * Fetches growth stage options from the dummy data.
 * @returns {Observable<Option[]>} An observable of the growth stage options.
 */
getGrowthStageOptions(): Observable<GrowthStageOptions[]> {
  return of(DUMMY_GROWTH_STAGE_OPTIONS); // Simulate API call with dummy data
}

}
