<!-- <vw-card customClasses="w-full" class="w-full bg-white">
  <div class="bg-white shadow-lg p-2 mb-2 rounded-xl flex items-center w-full" *ngIf="cultivation"
    [ngClass]="{'border border-primary listing-inn': selected}">
    <vw-icon-svg [iconUrl]="cultivationFileUrl || '/assets/svg/tomato-3.svg'"
      additionalClasses="cursor-pointer w-[50px]" width="50px" height="72px"></vw-icon-svg>
    <div class="pl-3 w-full py-1">
      <div class="flex items-center justify-between w-full pb-1">
        <p class="text-xs font-normal text-[#9BA6A1] flex items-center gap-1">
          {{ cultivation.name }}
        </p>
        <p class="text-xs font-normal text-[#9BA6A1] flex items-center gap-1">
          {{'garden.tabs.seeds.seed-item.expiration' | translate: {expirationDate: cultivation.expirationDate } }}
        </p>
      </div>
      <div class="flex gap-2 pb-[6px]">
        @if (cultivation.rating !== undefined ) {
        <p class="flex items-center gap-1 text-xs text-[#853C00]">
          <vw-icon-svg iconUrl="/assets/svg/star.svg" additionalClasses="" width="16px" height="16px"></vw-icon-svg>
          {{ cultivation.rating }}/5
        </p>
        }
      </div>
      <div class="w-full">
        <vw-progress-bar [progress]="20"></vw-progress-bar>
      </div>
    </div>
  </div>
</vw-card> -->

<vw-card class="w-full bg-white">
  <div class="h-16 p-2 flex gap-3 border border-gray-100 rounded-lg items-center"
    [ngClass]="{'outline outline-1 outline-primary': selected}">
    <div class="h-full w-16 rounded" [style.background-color]="cultivation.backgroundColor | HexToHsl">
      <vw-icon-svg [iconUrl]="cultivationFileUrl || '/assets/svg/tomato-3.svg'"
        additionalClasses="mx-auto h-full w-8/12 object-contain" width="100%" height="100%"></vw-icon-svg>
    </div>
    <div class="flex flex-col justify-center">
      <span class="text-lg font-medium text-primary-800 leading-5">{{cultivation.name }}</span>
      <span class="text-sm text-gray-400">{{getFamily()}}</span>
    </div>
  </div>
</vw-card>