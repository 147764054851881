import { inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, tap } from "rxjs";
import { map } from "rxjs/operators";
import {AuthApi} from '@TheGreenHouseCompany/vegiwise-sdk-typescript/dist/sdk/auth/auth.api';

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  private authApi: AuthApi

  constructor(private router: Router) {
    this.authApi = new AuthApi();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authApi.isAuthenticate().pipe(
      map((res) => {
        if (!res) {
          // Remove tokens from localStorage...
          this.authApi.logout();
          // Save current url & query params...
          this.authApi.memorizeURL(state.url.split("?")[0], route.queryParams);
          this.router.navigate(["/init"]);
        }
        return !!res;
      })
    );
  }
}

export const NavigationGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  return inject(PermissionsService).canActivate(next, state);
};
