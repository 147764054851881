<vw-card customClasses="w-full" class="w-full bg-white">
  <div  class="bg-white shadow-lg p-2 rounded-xl flex items-center w-full">
    <vw-icon-svg
      [iconUrl]="seed.picture || '/assets/svg/tomato-3.svg'"
      additionalClasses="cursor-pointer w-[50px]"
      width="50px"
      height="72px"
    ></vw-icon-svg>
    <div class="pl-3 w-full py-1">
      <div class="flex items-center justify-between w-full pb-1">
        <p class="text-xs font-normal text-[#9BA6A1] flex items-center gap-1">
          {{ seed.name }}
        </p>
        <p class="text-xs font-normal text-[#9BA6A1] flex items-center gap-1">
          {{'garden.tabs.seeds.seed-item.expiration' | translate: {expirationDate: seed.expirationDate } }}
        </p>
      </div>
      <div class="flex gap-2 pb-[6px]">
        <h2 class="text-xl text-[#0C0D0D] font-semibold">{{ seed.variety }}</h2>
        @if (seed.rating !== undefined ) {
          <p class="flex items-center gap-1 text-xs text-[#853C00]">
            <vw-icon-svg
              iconUrl="/assets/svg/star.svg"
              additionalClasses=""
              width="16px"
              height="16px"
            ></vw-icon-svg>
            {{ seed.rating }}/5
          </p>
        }
      </div>
      <div class="w-full">
        <vw-progress-bar [progress]="seed.progress"></vw-progress-bar>
      </div>
    </div>
  </div>
</vw-card>
